import React from "react";

import collectionIcon from "../assets/images/photos/Bankruptcies icon/collection icon.png"; // Replace with your actual icon paths
import studentLoanIcon from "../assets/images/photos/Bankruptcies icon/student loan icon.png";
import latePaymentIcon from "../assets/images/photos/Bankruptcies icon/late payment icon.png";
import medicalBillIcon from "../assets/images/photos/Bankruptcies icon/medical bills icon.png";
import hardInquiryIcon from "../assets/images/photos/Bankruptcies icon/hard inquiries.png";
import foreclosureIcon from "../assets/images/photos/Bankruptcies icon/forclosure icon.png";
import repossessionIcon from "../assets/images/photos/Bankruptcies icon/repossession icon.png";
import bankruptcyIcon from "../assets/images/photos/Bankruptcies icon/Bankruptcies icon.png";

const creditItems = [
  { title: "Collections", icon: collectionIcon },
  { title: "Student Loans", icon: studentLoanIcon },
  { title: "Late Payments", icon: latePaymentIcon },
  { title: "Medical Bills", icon: medicalBillIcon },
  { title: "Hard Inquiries", icon: hardInquiryIcon },
  { title: "Foreclosures", icon: foreclosureIcon },
  { title: "Repossessions", icon: repossessionIcon },
  { title: "Bankruptcies", icon: bankruptcyIcon },
];

export default function CreditElimination() {
  return (
    <div className="credit-elimination-section">
      <h2 className="section-heading">
        Eliminate Negative Records From Your <br /> Credit Report
      </h2>
      <div className="cards-container">
        {creditItems.map((item, index) => (
          <div key={index} className="credit-card">
            <img src={item.icon} alt={item.title} className="card-icon" />
            <p className="card-title">{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
