import React from "react";
import backgroundImg from "../assets/images/photos/unrecognizable-male-collar-manager-plastic.webp"; // Add your background image

export default function CreditSection({PhoneNumber , creditSectionRight , creditSectionLeft , CreditSecionPara}) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };
  // useEffect (()=>{

  // }, [cardRefs])
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div>
     
<div
      className="credit-section"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Overlay */}
      <div className="overlay">
        {/* Content Section */}
        <div className="content">
          {/* Left Content */}
          <div className="text-content">
            <h1 >{creditSectionLeft.heading}</h1>
            <p dangerouslySetInnerHTML={{ __html: creditSectionLeft.subtext }} />

              
          </div>

          {/* Call-to-Action */}
          <div className="cta-area">
            <h2>{creditSectionRight.heading}</h2>
            <p>
              {creditSectionRight.subtext}
            </p>
            <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW: {PhoneNumber.PhoneNo}</a>
          </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    
  );
}
