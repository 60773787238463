import React from "react";
import icon1 from "../assets/images/photos/strategy to improvement/share your information.png";
import icon2 from "../assets/images/photos/strategy to improvement/credit overview.png";
import icon3 from "../assets/images/photos/strategy to improvement/strategy to improvement.png";

export default function Services({ PhoneNumber }) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  // Array containing the service details
  const services = [
    {
      icon: icon1,
      heading: "Share Your Information",
      description: "We gather your details securely to access your credit report and verify your identity.",
    },
    {
      icon: icon2,
      heading: "Receive a Detailed Credit Overview",
      description: "Get a clear summary of your credit score and negative items from the professionals we work with.",
    },
    {
      icon: icon3,
      heading: "Customized Strategy for Improvement",
      description: "Receive a tailored plan designed by leading credit experts to boost your credit score.",
    },
    // Add more services here as needed
  ];

  return (
    <div className="Services">
      <div className="descr">
      <h2>Ready to Transform Your <br /> Credit  Score?</h2>
      <p>Here’s How We Facilitate Your Success</p>
      </div>
    
      <div className="card-container">
       
        {services.map((service, index) => (
          <div className="card" key={index}>
            <div className="card-inner">
              <div className="card-front">
                <div className="icon"><img src={service.icon} alt="" /> </div>
                <h3>{service.heading}</h3>
              </div>
              <div className="card-back">
                <p>{service.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
