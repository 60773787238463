import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function Disclaimer() {
  const PhoneNumber = {
    PhoneNo: "(877) 403-2065",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="33"
        class="elementor elementor-33"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-9e01249 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="9e01249"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-66629e6 animated fadeIn"
              data-id="66629e6"
              data-element_type="column"
              data-settings='{"animation":"fadeIn"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-70d0de6 elementor-widget elementor-widget-heading"
                  data-id="70d0de6"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      DISCLAIMER
                    </h1>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-be6ef60 elementor-widget elementor-widget-text-editor"
                  data-id="be6ef60"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      The information provided by Permanent Credit Repair (“we,”
                      “us,” or “our”) on permanentcreditrepair.com (the “Site”)
                      is for general informational purposes only. All
                      information on the Site is provided in good faith, however
                      we make no representation or warranty of any kind, express
                      or implied, regarding the accuracy, adequacy, validity,
                      reliability, availability, or completeness of any
                      information on the Site. UNDER NO CIRCUMSTANCE SHALL WE
                      HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY
                      KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR
                      RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE
                      OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE
                      SITE IS SOLELY AT YOUR OWN RISK.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-bb0e881 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="bb0e881"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-92a4ff2"
              data-id="92a4ff2"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section className="none"></section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-efe78b1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="efe78b1"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1635dd4 animated fadeIn"
                      data-id="1635dd4"
                      data-element_type="column"
                      data-settings='{"animation":"fadeIn"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-53d6f33 elementor-widget elementor-widget-heading"
                          data-id="53d6f33"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h4 class="elementor-heading-title elementor-size-default">
                              PROFESSIONAL DISCLAIMER
                            </h4>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-8024895 elementor-widget elementor-widget-text-editor"
                          data-id="8024895"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              The Site cannot and does not contain credit repair
                              advice. The credit repair information is provided
                              for general informational and educational purposes
                              only and is not a substitute for professional
                              advice. Accordingly, before taking any actions
                              based upon such information, we encourage you to
                              consult with the appropriate professionals. We do
                              not provide any kind of credit repair advice. THE
                              USE OR RELIANCE OF ANY INFORMATION CONTAINED ON
                              THE SITE IS SOLELY AT YOUR OWN RISK.
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-28f6ed8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="28f6ed8"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-bf35c1f elementor-invisible"
                      data-id="bf35c1f"
                      data-element_type="column"
                      data-settings='{"animation":"fadeIn"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-3704af4 elementor-widget elementor-widget-heading"
                          data-id="3704af4"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h4 class="elementor-heading-title elementor-size-default">
                              TCPA DISCLAIMER
                            </h4>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-90bc1a9 elementor-widget elementor-widget-text-editor"
                          data-id="90bc1a9"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              Notwithstanding any current or prior election to
                              opt in or opt out of receiving telemarketing
                              calls, or SMS messages (text messages) from our
                              representatives, agents, affiliates, you expressly
                              consent to be contacted by PERMANENT CREDIT
                              REPAIR, our agents, representatives, affiliates,
                              or anyone calling on our behalf. This is for any
                              purposes relating to your request for credit
                              repair service, at any telephone number, or
                              physical or electronic address you provide or at
                              which you may be reached. You agree we may contact
                              you in any way, including SMS messages (text
                              messages), calls using prerecorded messages or
                              artificial voice, and calls and messages delivered
                              using auto telephone dialing system or an
                              automatic texting system. Automated messages may
                              be played when the telephone is answered, whether
                              by you or someone else. In the event that an agent
                              or representative of PERMANENT CREDIT REPAIR
                              calls, he or she may also leave a message on your
                              answering machine, voice mail, or send one via
                              text. You certify that the telephone numbers that
                              you have provided, are in fact your contact
                              numbers, and you agree to receive calls at each
                              phone number you have provided PERMANENT CREDIT
                              REPAIR. You agree to promptly alert PERMANENT
                              CREDIT REPAIR in the event that you stop using a
                              particular phone number. Your cell/mobile
                              telephone provider will charge you according the
                              type of account you carry with those companies.
                              You may opt out of any future contact via text
                              message by replying anytime with “STOP”. You agree
                              that PERMANENT CREDIT REPAIR may contact you by
                              email, using any email address you have provided
                              or that you provide in the future. You may opt out
                              of any future contact via email message by
                              replying anytime with “UNSUBSCRIBE”. PERMANENT
                              CREDIT REPAIR may listen to and/or record calls
                              between you and any representative without notice,
                              as permitted by applicable laws. For example we
                              listen to calls to monitor for quality purposes.
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
