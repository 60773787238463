import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";

export default function PrivacyPolicy() {
  const PhoneNumber = {
    PhoneNo: "(877) 403-2065",
  };
  return (
    <>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="55"
        class="elementor elementor-55"
      >
        {" "}
        <section
          data-id="03047ed"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          class="elementor-section elementor-top-section elementor-element elementor-element-03047ed elementor-section-boxed elementor-section-height-default lazyloaded"
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-192d4a3  lazyloaded animated fadeInUp"
              data-id="192d4a3"
              data-element_type="column"
              nitro-elementor-animation="animated fadeInUp"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  class="elementor-element elementor-element-87d039c elementor-widget elementor-widget-heading animated none"
                  data-id="87d039c"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                  nitro-elementor-animation="animated none"
                >
                  {" "}
                  
                </div>{" "}
                <div
                  class="elementor-element elementor-element-573d220 elementor-widget elementor-widget-text-editor"
                  data-id="573d220"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    {" "}
                    <h1 class="elementor-heading-title elementor-size-default">
                      PRIVACY POLICY
                    </h1>{" "}
                  </div>{" "}
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <p>
                      Intermedia Marketing Group LLC operates the
                      permanentcreditrepair.com website, which provides the
                      SERVICE. This page informs website visitors regarding our
                      policies regarding the collection, use, and disclosure of
                      Personal Information if anyone decides to use our Service,
                      on the Permanent Credit Repair website. If you choose to
                      use our Service, then you agree to the collection and use
                      of information in relation to this policy. The Personal
                      Information that we collect is used for providing and
                      improving the Service. We will not use or share your
                      information except as described in this Privacy Policy.
                      The terms used in this Privacy Policy have the same
                      meanings as our Terms and Conditions, which are accessible
                      at permanentcreditrepair.com unless otherwise defined in
                      this Privacy Policy.
                    </p>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
       
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-10226a3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="10226a3"
          data-element_type="section"
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-966049a  lazyloaded animated fadeInUp animated fadeInUp"
              data-id="966049a"
              data-element_type="column"
              nitro-elementor-animation="animated fadeInUp"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
                <section className="none"></section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-dd4046d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="dd4046d"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a0b093a"
                      data-id="a0b093a"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-bf63c15 elementor-widget elementor-widget-heading"
                          data-id="bf63c15"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              DEFINITIONS
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-3d0c87c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="3d0c87c"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4534be3"
                      data-id="4534be3"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-7f81d20 elementor-widget elementor-widget-heading"
                          data-id="7f81d20"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Service
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-902135a elementor-widget elementor-widget-text-editor"
                          data-id="902135a"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Service is the PermanentCreditRepair.com operated
                              by Intermedia Marketing Group LLC.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-2b3cafa elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="2b3cafa"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a86ae34"
                      data-id="a86ae34"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-ea5f4ed elementor-widget elementor-widget-heading"
                          data-id="ea5f4ed"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Personal Data
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-48e5ae1 elementor-widget elementor-widget-text-editor"
                          data-id="48e5ae1"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Personal Data pertains to information about a
                              living person(s) who can be identified from this
                              data or other information in our possession or
                              likely to come into our possession.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-b055a5d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="b055a5d"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-3a57f13"
                      data-id="3a57f13"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-9fbfb62 elementor-widget elementor-widget-heading"
                          data-id="9fbfb62"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Usage Data
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-91e5f46 elementor-widget elementor-widget-text-editor"
                          data-id="91e5f46"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Usage Data is collected automatically and is
                              generated by the use of the Service, such as the
                              duration of a visit to the site.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-f41a65f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="f41a65f"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a4cfd19"
                      data-id="a4cfd19"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-e2d13b6 elementor-widget elementor-widget-heading"
                          data-id="e2d13b6"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Cookies
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-428c940 elementor-widget elementor-widget-text-editor"
                          data-id="428c940"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Cookies are files with a small amount of data
                              commonly used as a unique anonymous identifier.
                              These are sent to your browser from the website
                              you visit and stored on your computer’s hard
                              drive.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-0834f6b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="0834f6b"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-640fb6c"
                      data-id="640fb6c"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-0c90f0c elementor-widget elementor-widget-heading"
                          data-id="0c90f0c"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Data Controller
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-315a7eb elementor-widget elementor-widget-text-editor"
                          data-id="315a7eb"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Data Controller pertains to the legal entity tho
                              controls your information while participating in
                              the Service. We are a Data Controller of your
                              personal information. We determine the purposes
                              for which it will be processed and how it’s done
                              in an effective manner that ensures compliance
                              with all relevant laws while still maintaining the
                              trust of our users.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-5e53975 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="5e53975"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a71fd07"
                      data-id="a71fd07"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-f7abb01 elementor-widget elementor-widget-heading"
                          data-id="f7abb01"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Data Processors (or Service Providers)
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-6de58c7 elementor-widget elementor-widget-text-editor"
                          data-id="6de58c7"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              The Data Processor (or Service Provider) is any
                              natural or legal person who processes data on
                              behalf of the Data Controller. We may use various
                              service providers to process your information more
                              effectively.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-e01033f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="e01033f"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f60c3cc"
                      data-id="f60c3cc"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-666174e elementor-widget elementor-widget-heading"
                          data-id="666174e"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Data Subject (or User)
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-bf26805 elementor-widget elementor-widget-text-editor"
                          data-id="bf26805"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Data Subjects are any individuals who use our
                              Service and whose Personal Data we collect.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-3ecc20a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="3ecc20a"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-37938db"
                      data-id="37938db"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-f561f9f elementor-widget elementor-widget-heading"
                          data-id="f561f9f"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Information Collection and Use
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-fc329b5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fc329b5"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-8425e6b"
                      data-id="8425e6b"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-e6e52f3 elementor-widget elementor-widget-heading"
                          data-id="e6e52f3"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Personal Data
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-26bf928 elementor-widget elementor-widget-text-editor"
                          data-id="26bf928"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              For a better experience, while using our Service,
                              we may require you to provide us with certain
                              personally identifiable information, including but
                              not limited to your name, phone number, and postal
                              address. The information that we collect will be
                              used to contact or identify you.
                            </p>
                            <p>
                              We will only use your Personal Data to contact you
                              with newsletters, marketing or promotional
                              materials, and other information that may be
                              interesting. You can opt out by following
                              instructions in any email we send — however, this
                              means unsubscribing from all emails which could
                              contain important updates.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-9112bfe elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="9112bfe"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-78f4698"
                      data-id="78f4698"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-be1046a elementor-widget elementor-widget-heading"
                          data-id="be1046a"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Usage Data
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-964154a elementor-widget elementor-widget-text-editor"
                          data-id="964154a"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              We want to inform you that whenever you visit our
                              Service, we collect information that your browser
                              sends to us called Usage Data. This Usage Data may
                              include information such as your computer’s
                              Internet Protocol (“IP”) address, browser version,
                              pages of our Service that you visit, the time and
                              date of your visit, the time spent on those pages,
                              and other statistics.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-296d9ba elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="296d9ba"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-5735802"
                      data-id="5735802"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-c66af17 elementor-widget elementor-widget-heading"
                          data-id="c66af17"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Location Data
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-078f292 elementor-widget elementor-widget-text-editor"
                          data-id="078f292"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Location data is used to provide features of our
                              service and improve and customize the way in which
                              we offer it. You control when your location
                              services are activated or deactivated by using
                              settings on any device you use for internet
                              browsing with us — including mobile devices.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-43f19a1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="43f19a1"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ab4fe34"
                      data-id="ab4fe34"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-ede3120 elementor-widget elementor-widget-heading"
                          data-id="ede3120"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Cookie Data{" "}
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-edc5a95 elementor-widget elementor-widget-text-editor"
                          data-id="edc5a95"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Our website uses Cookies to collect information
                              and to improve our Service. You can either accept
                              or refuse these cookies and know when a cookie is
                              being sent to your computer. If you choose to
                              refuse our cookies, you may not be able to use
                              some portions of our Service.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-daa40f7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="daa40f7"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6febc67"
                      data-id="6febc67"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-46e831e elementor-widget elementor-widget-heading"
                          data-id="46e831e"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                            Examples of Cookies we use include:{" "}
                            </h4>{" "}
                            
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-951ed37 elementor-widget elementor-widget-text-editor"
                          data-id="951ed37"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              ► Session Cookies — allows for Service operation
                              <br />► Preference Cookies — allows for
                              remembering your preferences and setting
                              <br />► Security Cookies — for security purposes
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-15c765f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="15c765f"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ef91a10"
                      data-id="ef91a10"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-af2a46a elementor-widget elementor-widget-heading"
                          data-id="af2a46a"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                            Service Providers{" "}
                            </h4>{" "}
                            
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-6bd79c0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="6bd79c0"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7998c56"
                      data-id="7998c56"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-e00e2de elementor-widget elementor-widget-heading"
                          data-id="e00e2de"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              We may employ third-party companies and
                              individuals due to the following reasons:
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-06ddd19 elementor-widget elementor-widget-text-editor"
                          data-id="06ddd19"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              ► To facilitate our Service;
                              <br />► To provide the Service on our behalf;
                              <br />► To perform Service-related services; or
                              <br />► To assist us in analyzing how our Service
                              is used.
                            </p>
                            <p>
                              We want to inform our Service users that these
                              third parties have access to your Personal Data.
                              The reason is to perform the tasks assigned to
                              them on our behalf. However, they are obligated
                              not to disclose or use the information for any
                              other purpose.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-dd0a306 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="dd0a306"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-eb4f446"
                      data-id="eb4f446"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-9ee7ef5 elementor-widget elementor-widget-heading"
                          data-id="9ee7ef5"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Security
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-32f9468 elementor-widget elementor-widget-text-editor"
                          data-id="32f9468"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              We value your trust in providing us with your
                              Personal Data. Thus, we strive to use commercially
                              acceptable means of protecting it. But remember
                              that no method of transmission over the internet
                              or method of electronic storage is 100% secure and
                              reliable, and we cannot guarantee its absolute
                              security.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-a0d44b7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="a0d44b7"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1174f33"
                      data-id="1174f33"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-ecf1a6b elementor-widget elementor-widget-heading"
                          data-id="ecf1a6b"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Use of Data
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-b51e0e9 elementor-widget elementor-widget-text-editor"
                          data-id="b51e0e9"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Intermedia Marketing Group LLC collects your
                              browsing data so to provide you with various
                              services. The collected information is used for
                              several purposes, including:
                            </p>
                            <p>
                              ► To notify users about changes in service
                              offerings or adjustments based on user feedback
                              <br />► Maintain an effective customer experience
                              by gathering analysis of how people are using the
                              Service’s features when allowed to participate via
                              email newsletter subscription process (if
                              applicable) <br />► Monitoring technical issues
                              affecting performance across their network which
                              may lead to providing news updates/special offers
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
        <section
          data-id="0bb29aa"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          class="elementor-section elementor-top-section elementor-element elementor-element-0bb29aa elementor-section-boxed elementor-section-height-default elementor-section-height-default nitro-lazy"
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5b373bc elementor-invisible nitro-lazy"
              data-id="5b373bc"
              data-element_type="column"
              nitro-elementor-animation="animated fadeInUp"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  class="elementor-element elementor-element-02fedf6 elementor-widget elementor-widget-heading"
                  data-id="02fedf6"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <h4 class="elementor-heading-title elementor-size-default">
                      Processing Personal Data under the General Data Protection
                      Regulation (GDPR)
                    </h4>{" "}
                  </div>{" "}
                </div>{" "}
                <div
                  class="elementor-element elementor-element-8f5bba6 elementor-widget elementor-widget-text-editor"
                  data-id="8f5bba6"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <p>
                      If you are from the European Economic Area (EEA),
                      Intermedia Marketing Group LLC may collect and use the
                      Personal Data described in this Privacy Policy depending
                      on the specific context in which it is collected.
                      Intermedia Marketing Group LLC may process your Personal
                      Data due to:
                      <br />
                      <br />► A contract needing to be performed and fulfilled
                      <br />► You have given us express permission to do so
                      <br />► Processing is in our legitimate interests and does
                      not override your rights
                      <br />► Compliance with the law
                    </p>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-e4f1026 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e4f1026"
          data-element_type="section"
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-472b645 elementor-invisible nitro-lazy"
              data-id="472b645"
              data-element_type="column"
              nitro-elementor-animation="animated fadeInUp"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
                <section className="none"></section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-db7741b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="db7741b"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-10408b0"
                      data-id="10408b0"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-bd3a410 elementor-widget elementor-widget-heading"
                          data-id="bd3a410"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Retention of Data
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-2ed657e elementor-widget elementor-widget-text-editor"
                          data-id="2ed657e"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Intermedia Marketing Group LLC will retain your
                              Personal Data for as long as necessary for the
                              sole purposes set forth within this Privacy
                              Policy. We will retain and use your Personal Data
                              only to comply with legal obligations, resolve
                              disputes, and enforce our legal agreements and
                              policies. Intermedia Marketing Group LLC will also
                              retain Usage Data for internal analysis, such as
                              when needed to strengthen the security or improve
                              the functionality of our Service. We will also
                              retain this data as legally obligated to do so.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-7d3a19b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="7d3a19b"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-8fd322f"
                      data-id="8fd322f"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-1616bfc elementor-widget elementor-widget-heading"
                          data-id="1616bfc"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Transfer of Data
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-978259c elementor-widget elementor-widget-text-editor"
                          data-id="978259c"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Your information, including your Personal Data,
                              may be transferred to and maintained on computers
                              located outside of your state, province, country,
                              or other governmental jurisdiction where data
                              protection laws may differ from those in your
                              jurisdiction. By providing us with any information
                              you choose to share, including Personal Data like
                              name and address or phone number, you give
                              Intermedia Marketing Group LLC permission to
                              transfer that data across borders within the
                              United States (and other countries), which is
                              processed there. You agree this Privacy Policy
                              followed means to accept all obligations imposed
                              upon its use at destinations outside the United
                              States.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-801d548 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="801d548"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-c34aac2"
                      data-id="c34aac2"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-46517e0 elementor-widget elementor-widget-heading"
                          data-id="46517e0"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Disclosure of Data
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-cf6da34 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="cf6da34"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-dffd8a5"
                      data-id="dffd8a5"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-3c86f05 elementor-widget elementor-widget-heading"
                          data-id="3c86f05"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Disclosure for Law Enforcement
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-b52baaa elementor-widget elementor-widget-text-editor"
                          data-id="b52baaa"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Intermedia Marketing Group LLC has a
                              responsibility to protect your Personal Data. We
                              will never release any of it without complying
                              with all applicable laws and responding properly
                              if asked by law enforcement or other authorities.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-196a546 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="196a546"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-27748f9"
                      data-id="27748f9"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-6fd79ca elementor-widget elementor-widget-heading"
                          data-id="6fd79ca"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Legal Requirements
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-93b731b elementor-widget elementor-widget-text-editor"
                          data-id="93b731b"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Intermedia Marketing Group LLC may disclose your
                              Personal Data in the good faith belief that such
                              action is necessary to: <br />
                              <br />► To notify users about changes in service
                              offerings or adjustments based on user feedback{" "}
                              <br />► Comply with a legal obligation <br />►
                              Protect and defend the rights or property of
                              Intermedia Marketing Group LLC <br />► Protect
                              clients &amp; employees from any harm caused by
                              their actions where it cannot be avoided through
                              reasonable precautions taken beforehand <br />►
                              Prevent or investigate potential wrongdoing in
                              connection with the Service <br />► Protect the
                              personal safety of users of the Service <br />►
                              Protect against personal liability
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-60fba7f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="60fba7f"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-513ff07"
                      data-id="513ff07"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-dbc615d elementor-widget elementor-widget-heading"
                          data-id="dbc615d"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Security of Data
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-4372cb9 elementor-widget elementor-widget-text-editor"
                          data-id="4372cb9"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              The security of your data is a top priority for
                              us. We’ll do everything we can to protect it, but
                              remember that no electronic storage media method
                              will ever be 100% secure when sending information
                              over the internet.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-3ee7e40 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="3ee7e40"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-3cad4ef"
                      data-id="3cad4ef"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-6e5f2aa elementor-widget elementor-widget-heading"
                          data-id="6e5f2aa"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Our Policy on “Do Not Track” Signals under the
                              California Online Protection Act
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d36abac"
                      data-id="d36abac"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-e8472d7 elementor-widget elementor-widget-image"
                          data-id="e8472d7"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-10507c4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="10507c4"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-0c1adf8"
                      data-id="0c1adf8"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-47b4bf8 elementor-widget elementor-widget-text-editor"
                          data-id="47b4bf8"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              We do not support Do Not Track (DNT). You can set
                              this preference in your web browser to inform
                              websites that they should not track your activity.
                              You can enable or disable this feature through the
                              preferences menu on any given website by adjusting
                              the appropriate settings on your web browser.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-47cbcd8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="47cbcd8"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c382379"
                      data-id="c382379"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-a79db04 elementor-widget elementor-widget-heading"
                          data-id="a79db04"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Your Data Protection Rights Under GDPR
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ac7cc19"
                      data-id="ac7cc19"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-9d51532 elementor-widget elementor-widget-image"
                          data-id="9d51532"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                           
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-989e13d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="989e13d"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6d32fef"
                      data-id="6d32fef"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-8cfd48f elementor-widget elementor-widget-text-editor"
                          data-id="8cfd48f"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              If you are a resident of the European Economic
                              Area (EEA), then we aim to make certain that your
                              Personal Data is processed fairly and in
                              accordance with data protection laws. Should you
                              request information about the Personal Data we
                              hold about you or want it removed, please contact
                              us so we may act accordingly. You may also have
                              the following data protection rights:
                            </p>
                            <p>
                              ► The right to be informed — You have the right to
                              be told how your Personal Data will be processed
                              by the Data Controller. This is normally done
                              through a privacy notice. <br />► The right to
                              access your Personal Data — You have the right to
                              ask the Data Controller whether they are
                              processing your Personal Data, and if they are,
                              you can request a copy of that personal
                              information. <br />► The right of rectification —
                              You have the right to ensure your information is
                              rectified if it is found to be incomplete or
                              inaccurate. <br />► The right to have your
                              Personal Data erased — Also known as the “right to
                              be forgotten” and, in certain circumstances, you
                              can ask the Data Controller to erase your data.{" "}
                              <br />► The right to withdraw consent — You have
                              the right to withdraw your consent at any point in
                              time when Intermedia Marketing Group LLC relies on
                              your consent to process personal information.{" "}
                              <br />► The right to restrict processing of your
                              Personal Data — You have the right to ask the Data
                              Controller to restrict or stop processing your
                              Personal Data under certain circumstances. <br />►
                              The right to data portability — You have the
                              right, in certain circumstances, to ask the Data
                              Controller for a copy of your data to re-use for
                              your own purposes. <br />► The right to object —
                              You have the right, in certain circumstances, to
                              object to the Data Controller processing your
                              data. <br />► Rights in relation to automated
                              decision making and profiling — You have the
                              right, in certain circumstances, to ask the Data
                              Controller not to make decisions about you that
                              are based solely on automated processing or
                              profiling Please note that to remain compliant
                              with law enforcement, we may ask that you verify
                              your identity before responding to or taking
                              action on your request. You also have the right to
                              file a complaint with a Data Protection Authority
                              about our collection and use of your information.
                              If you have questions or want more information,
                              please contact your local European Economic Area
                              (EEA) data protection authority.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
        <section
          data-id="e83a622"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          class="elementor-section elementor-top-section elementor-element elementor-element-e83a622 elementor-section-boxed elementor-section-height-default elementor-section-height-default nitro-lazy"
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-261b277 elementor-invisible nitro-lazy"
              data-id="261b277"
              data-element_type="column"
              nitro-elementor-animation="animated fadeInUp"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  class="elementor-element elementor-element-8460800 elementor-widget elementor-widget-heading"
                  data-id="8460800"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <h4 class="elementor-heading-title elementor-size-default">
                      Service Providers
                    </h4>{" "}
                  </div>{" "}
                </div>{" "}
                <div
                  class="elementor-element elementor-element-68cc4ae elementor-widget elementor-widget-text-editor"
                  data-id="68cc4ae"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <p>
                      We may employ third-party companies (Service Providers) to
                      help us deliver our Service, such as by handling customer
                      support or sending email newsletters about new services.
                      These Service Providers have access to Personal Data but
                      are prohibited from disclosing any information you shared
                      with them unless necessary. Service Providers are only to
                      perform tasks on our behalf and may not use or disclose
                      your Personal Data for any other purpose.
                    </p>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-4717176 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="4717176"
          data-element_type="section"
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b2b5af3 elementor-invisible nitro-lazy"
              data-id="b2b5af3"
              data-element_type="column"
              nitro-elementor-animation="animated fadeInUp"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
               <section className="none" ></section>
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-39bf54e"
                      data-id="39bf54e"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-2dba68c elementor-widget elementor-widget-heading"
                          data-id="2dba68c"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Analytics
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-06e3aac elementor-widget elementor-widget-text-editor"
                          data-id="06e3aac"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              We may use third-party Service Providers to
                              complete an analysis of the Service’s use and
                              monitor user activity.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
              
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-69c8e72 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="69c8e72"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ddd8689"
                      data-id="ddd8689"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-2114cf2 elementor-widget elementor-widget-heading"
                          data-id="2114cf2"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Google Analytics{" "}
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-c5068c7 elementor-widget elementor-widget-text-editor"
                          data-id="c5068c7"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Google uses the data collected from its Analytics
                              service to tailor a more personalized user
                              experience. The information gathered by Google
                              Analytics doesn’t contain any personally
                              identifiable data, like your name or email
                              address. It simply records how many visitors there
                              were on a specific day and time frame. You can
                              choose to opt out of making your activity on the
                              Service available to Google Analytics by
                              installing the appropriate browser add-on. The
                              Google Analytics opt-out browser add-on prevents
                              sharing information with Google Analytics about
                              your activity. For additional information on
                              Google’s privacy practices, visit the Google
                              Privacy Terms web page found here.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-0ade6fc elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="0ade6fc"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-c997c1d"
                      data-id="c997c1d"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-ca7bbe1 elementor-widget elementor-widget-heading"
                          data-id="ca7bbe1"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Behavioral Remarketing{" "}
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-508ab0a elementor-widget elementor-widget-text-editor"
                          data-id="508ab0a"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Intermedia Marketing Group LLC uses remarketing
                              services to advertise on other third-party
                              websites you visit after visiting the Service.
                              Cookies are used to inform, optimize, and serve
                              advertisements based on prior visits to the
                              Service.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-977f964 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="977f964"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-c844557"
                      data-id="c844557"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-9456079 elementor-widget elementor-widget-heading"
                          data-id="9456079"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Google AdWords
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-7caf424 elementor-widget elementor-widget-text-editor"
                          data-id="7caf424"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Google AdWords is a service provided by Google
                              Inc., which allows Intermedia Marketing Group LLC
                              to advertise the Service through online
                              advertisements. You can opt-out of this
                              advertising program to not have data collected and
                              used for personalized content based on visitors’
                              browsing behavior when using web browsers such as
                              Chrome and Firefox.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-fe77c3a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fe77c3a"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-451dbfd"
                      data-id="451dbfd"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-22eb5f3 elementor-widget elementor-widget-heading"
                          data-id="22eb5f3"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Bing Ads Remarketing{" "}
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-ae02899 elementor-widget elementor-widget-text-editor"
                          data-id="ae02899"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Bing Ads Remarketing is provided by Microsoft Inc.
                              You can also choose to opt-out of Bing Ads by
                              following their instructions located here. Learn
                              about Microsoft privacy policies by visiting their
                              Privacy Policy page.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-42de608 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="42de608"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-359e61b"
                      data-id="359e61b"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-52858e4 elementor-widget elementor-widget-heading"
                          data-id="52858e4"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Links to Other Sites
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-5554ac6 elementor-widget elementor-widget-text-editor"
                          data-id="5554ac6"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Our Service may contain links to other sites. If
                              you click on a third-party link, you will be
                              directed to that site. Note that these external
                              sites are not operated by us. Therefore, we
                              strongly advise you to review the Privacy Policy
                              of these websites. We have no control over and
                              assume no responsibility for any third-party sites
                              or services’ content, privacy policies, or
                              practices.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-fbd2960 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fbd2960"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-419de6f"
                      data-id="419de6f"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-1591335 elementor-widget elementor-widget-heading"
                          data-id="1591335"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Children's Privacy
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-e5ea336 elementor-widget elementor-widget-text-editor"
                          data-id="e5ea336"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              Our Services do not address anyone under the age
                              of 13. We do not knowingly collect personally
                              identifiable information from children under 13.
                              If we discover that a child under 13 has provided
                              us with personal information, we immediately
                              delete this from our servers. If you are a parent
                              or guardian and you are aware that your child has
                              provided us with personal information, please
                              contact us so that we will be able to take the
                              necessary actions.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-3ba5ce7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="3ba5ce7"
                  data-element_type="section"
                >
                  {" "}
                  <div class="elementor-container elementor-column-gap-default">
                    {" "}
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-510e531"
                      data-id="510e531"
                      data-element_type="column"
                    >
                      {" "}
                      <div class="elementor-widget-wrap elementor-element-populated">
                        {" "}
                        <div
                          class="elementor-element elementor-element-f39ae73 elementor-widget elementor-widget-heading"
                          data-id="f39ae73"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <h4 class="elementor-heading-title elementor-size-default">
                              Changes to This Privacy Policy{" "}
                            </h4>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div
                          class="elementor-element elementor-element-8ad2b05 elementor-widget elementor-widget-text-editor"
                          data-id="8ad2b05"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {" "}
                          <div class="elementor-widget-container">
                            {" "}
                            <p>
                              We may update our Privacy Policy from time to
                              time. Thus, we advise you to review this page
                              periodically for any changes. We will notify you
                              of any changes by posting the new Privacy Policy
                              on this page. These changes are effective
                              immediately after they are posted on this page.
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </section>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </>
  );
}
