import React from "react";
import Image1 from "../assets/images/photos/cards/financial constraints.webp";
import Image2 from "../assets/images/photos/cards/reclaim your financial freedom.webp";
import Image3 from "../assets/images/photos/cards/stress full financial barrier.webp";
export default function HowItWorks({ PhoneNumber }) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  // Array containing the How It Works steps
  const steps = [
    {
      image: Image1, // Replace with your image URL
      heading: "Live Life Without Financial Constraints",
     
      icon: "⚙️",
      description:
        "A poor credit rating can limit access to crucial financial tools and opportunities. By partnering with the right credit repair specialists, you can improve your score and unlock a higher quality of life. Our platform ensures a seamless process, so you can focus on what matters most.",
    },
    {
      image: Image2,
      heading: "Reclaim Your Financial Independence",
     
  
      description:
        "Imagine the peace of mind that comes with having the financial flexibility you deserve. Our partners are here to help you improve your credit score efficiently and effectively.",
    },
    {
      image: Image3,
      heading: "Say Goodbye to Stressful Financial Barriers!",
   
   
      description:
        "Don’t waste time with endless paperwork and calls. Let the experts handle the details while you focus on what truly matters in your life.",
    },
    // Add more steps as needed
  ];

  return (
    <div className="HowWorks">
      <p className="desc">Achieve financial empowerment by working with specialists dedicated to enhancing your credit efficiently.</p>
      <div className="card-container">
        {steps.map((step, index) => (
          <div className="card" key={index}>
           <div className="card-image">
              <img src={step.image} alt={step.heading} />
            </div>
            <div className="card-content">
              <h3>{step.heading}</h3>
              
              
              <p>{step.description}</p>
            </div>
            
          </div>
        ))}
      </div>
      <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW: {PhoneNumber.PhoneNo}</a>
          </div>
    </div>
  );
}
