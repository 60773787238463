import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import image1 from "./assets/images/photos/1.webp";
import image2 from "./assets/images/photos/2.webp";
import CreditSection from "./components/CreditSection";
import CreditElimination from "./components/CreditElimination";
export default function landingPage() {
  const PhoneNumber = {
    PhoneNo: "(877) 403-2065",
  };
  const slide = [
    {
      image: image1,
      heading: `Unlock Your Financial Freedom 
with a Better Credit Score`,
      subheading: "Connect with the Best in Credit Repair Today",
      Describtion: ``,
    },
    {
      image: image2,
      heading: `Unlock Your Financial Freedom 
with a Better Credit Score`,
      subheading: "Connect with the Best in Credit Repair Today",
      Describtion: ``,
    },
  ];
  const creditSectionLeft = {
    heading: `Not All Credit Solutions Are Created Equal`,
    subtext: `We work closely with you to understand your specific needs and offer a bespoke strategy that fits your situation perfectly.`
  };
  const creditSectionRight = {
    heading: `Don’t Delay! Contact Us Today to Begin Your Credit Repair Journey!`,
    subtext: `Starting is simple. Share a few details with us, and we’ll introduce you to the top credit repair professionals who will take it from there.`

  };
  const ContactText = {
    Heading: `Take Charge of Your Financial Future Today!`,
    Describtion: `Connect with Top Experts and Elevate Your Credit Score Today`,
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection slide={slide} PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />

      <Services PhoneNumber={PhoneNumber} />
      <HowItWorks PhoneNumber={PhoneNumber} />
      <CreditSection creditSectionLeft={creditSectionLeft} creditSectionRight={creditSectionRight} PhoneNumber={PhoneNumber} />
      <CreditElimination />
      <Contact ContactText={ContactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
