import React from "react";
import image from "../assets/images/photos/no-credit-score-debt-deny-concept.webp"
export default function Contact({ PhoneNumber, ContactText }) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  return (
    <div className="contact-section">
      <div className="contact-content">
        <h1 className="contact-heading">{ContactText.Heading}</h1>
        <p className="contact-subheading">{ContactText.Describtion}</p>
        <div className="btn-group">
          <a href={`tel:${telPhoneNumber}`}>CALL NOW: {PhoneNumber.PhoneNo}</a>
        </div>
      </div>
      <div className="contact-image">
        <img
          src={image}
          alt="Contact Us"
        />
      </div>
    </div>
  );
}
