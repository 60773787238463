import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_gg_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import CreditSection from "../components/CreditSection";
import CreditElimination from "../components/CreditElimination";
import image1 from "../assets/images/photos/unrecognizable-male-collar-manager-plastic.webp";
import image2 from "../assets/images/photos/young-couple-checking-their-family-budget.webp";
export default function GgLp3() {
  useLoadScript("../Scripts/Home_gg_lps.js");
  const PhoneNumber = {
    PhoneNo: "(866) 582-6948",
  };
  const slide = [
    {
      image: image1,
      heading: `Break Free from Financial Limitations`,
      subheading: "Get Connected with Credit Repair Experts Who Can Help",
      Describtion: ``,
    },
    {
      image: image2,
      heading: `Break Free from Financial Limitations`,
      subheading: "Get Connected with Credit Repair Experts Who Can Help",
      Describtion: ``,
    },
  ];
  const CreditSecionPara ={
    para: ``
  }
  const creditSectionLeft = {
    heading: `Take Charge of Your Financial Future`,
    subtext: `Reclaim control over your financial life .<br/>
Enjoy the peace of mind that comes with improved credit .<br/>
Open doors to new opportunities and achieve your dreams .`
  };
  const creditSectionRight = {
    heading: `Ready for a fresh start? Reach out now to connect with experts who can help elevate your credit score.`,
    subtext: ``

  };
  const ContactText = {
    Heading: `Step into a Better Financial Life`,
    Describtion: `Call Now to Connect with the Experts Who Can Make It Happen`,
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection slide={slide} PhoneNumber={PhoneNumber} />

      <CreditSection CreditSecionPara={CreditSecionPara} creditSectionLeft={creditSectionLeft} creditSectionRight={creditSectionRight} PhoneNumber={PhoneNumber} />
      <CreditElimination />
      <Contact ContactText={ContactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
