import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../assets/images/photos/slider-1.5e37feb9.jpg";
import image2 from "../assets/images/photos/slider-2.e69c26fe.png";

export default function HeroSection({ PhoneNumber , slide }) {
  // Phone number formatting
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  // Slide data (background image, heading, subheading, etc.)

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <div className="HeroSection">
      <Slider {...settings}>
        {slide.map((slide, index) => (
          <div key={index} className="slide">
            <div
              className="slide-background"
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <div className="slide-content">
                <h1>{slide.heading}</h1>
                <h3>{slide.subheading}</h3>
                <span>{slide.Describtion}</span>
                <div className="btn-group">
                  <a href={`tel:${telPhoneNumber}`}>
                    {" "}
                    CALL NOW: {PhoneNumber.PhoneNo}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

// Custom arrow components
const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return <div className={`${className} custom-arrow next`} onClick={onClick} />;
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return <div className={`${className} custom-arrow prev`} onClick={onClick} />;
};
