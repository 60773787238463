import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_gg_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import CreditElimination from "../components/CreditElimination";
import CreditSection from "../components/CreditSection";
import image1 from "../assets/images/photos/3.webp";
import image2 from "../assets/images/photos/credit-score-financial-banking-economy-concept.webp";
export default function GgLp1() {
  useLoadScript("../Scripts/Home_gg_lps.js");
  const PhoneNumber = {
    PhoneNo: "(866) 582-6948",
  };
  const slide = [
    {
      image: image1,
      heading: `Your Path to Better Credit Starts Here`,
      subheading: "Discover How Our Specialists Can Elevate Your Credit Score",
      Describtion: ``,
    },
    {
      image: image2,
      heading: `Your Path to Better Credit Starts Here`,
      subheading: "Discover How Our Specialists Can Elevate Your Credit Score",
      Describtion: ``,
    },
  ];
  const CreditSecionPara ={
    para: ``
  }
  const creditSectionLeft = {
    heading: `Take Charge of Your Financial Future`,
    subtext: `Reclaim control over your financial life . <br/>
Enjoy the peace of mind that comes with improved credit . <br/>
Open doors to new opportunities and achieve your dreams .`
  };
  const creditSectionRight = {
    heading: `Ready for a fresh start? Reach out now to connect with experts who can help elevate your credit score.`,
    subtext: ``

  };
  const ContactText = {
    Heading: `Achieve Your Credit Goals with Expert Help`,
    Describtion: `Don’t Wait—Start Your Journey to a Better Credit Score Now`,
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection slide={slide} PhoneNumber={PhoneNumber} />

      <CreditSection CreditSecionPara={CreditSecionPara} creditSectionLeft={creditSectionLeft} creditSectionRight={creditSectionRight} PhoneNumber={PhoneNumber} />
      <CreditElimination />
      <Contact ContactText={ContactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
