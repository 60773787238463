import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function TermsCondition() {
  const PhoneNumber = {
    PhoneNo: "(877) 403-2065",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="64"
        class="elementor elementor-64"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-05ca277 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="05ca277"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a989d0d animated fadeInUp"
              data-id="a989d0d"
              data-element_type="column"
              data-settings='{"animation":"fadeInUp"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-e6aeab1 elementor-widget elementor-widget-heading"
                  data-id="e6aeab1"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      TERMS &amp; CONDITIONS
                    </h1>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-54314dc elementor-widget elementor-widget-text-editor"
                  data-id="54314dc"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      These Terms and Conditions constitute a legally binding
                      agreement made between you, whether personally or on
                      behalf of an entity (“you”) and Permanent Credit Repair
                      (“we,” “us,” or “our”), concerning your access to and use
                      of the permanentcreditrepair.com website as well as any
                      other media form, media channel, mobile website or mobile
                      application related, linked, or otherwise connected to it
                      (collectively, the “Site”). You agree that by accessing
                      the Site, you have read, understood, and agree to be bound
                      by all of these Terms and Conditions. If you do not agree
                      with all of these Terms and Conditions, you are expressly
                      prohibited from using the Site and must discontinue use
                      immediately.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-59d4c9f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="59d4c9f"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c85271f animated fadeInUp"
              data-id="c85271f"
              data-element_type="column"
              data-settings='{"animation":"fadeInUp"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-412b29c elementor-widget elementor-widget-heading"
                  data-id="412b29c"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h4 class="elementor-heading-title elementor-size-default">
                      Intellectual Property
                    </h4>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-2bec666 elementor-widget elementor-widget-text-editor"
                  data-id="2bec666"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      The Site may contain (or you may be sent via the Site)
                      links to other websites (“Third-Party Websites”) as well
                      as articles, photographs, text, graphics, pictures,
                      designs, music, sound, video, information, applications,
                      software, and other content or items belonging to or
                      originating from third parties (“Third-Party Content”).
                    </p>
                    <p>
                      Such Third-Party Websites and Third-Party Content are not
                      investigated, monitored, or checked for accuracy,
                      appropriateness, or completeness by us. We are not
                      responsible for any Third-Party Websites accessed through
                      the Site or any Third-Party Content posted on, available
                      through, or installed from the Site, including the
                      content, accuracy, offensiveness, opinions, reliability,
                      privacy practices, or other policies of or contained in
                      the Third-Party Websites or the Third-Party Content.
                    </p>
                    <p>
                      Inclusion of, linking to, or permitting the use or
                      installation of any Third-Party Websites or any
                      Third-Party Content does not imply approval or endorsement
                      thereof. If you decide to leave the Site and access the
                      Third-Party Websites or to use or install any Third-Party
                      Content, you do so at your own risk, and you should be
                      aware these Terms and Conditions no longer govern. We
                      strongly advise you to read the Third-Party Website terms
                      and conditions and the privacy policies of any site or
                      services you visit or use.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-1dcbded elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="1dcbded"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-90c8b14 elementor-invisible"
              data-id="90c8b14"
              data-element_type="column"
              data-settings='{"animation":"fadeInUp"}'
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-7b8afe6 elementor-widget elementor-widget-heading"
                  data-id="7b8afe6"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h4 class="elementor-heading-title elementor-size-default">
                      Indemnification
                    </h4>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-85582aa elementor-widget elementor-widget-text-editor"
                  data-id="85582aa"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      You agree to defend, indemnify, and hold Permanent Credit
                      Repair harmless, including our subsidiaries, affiliates,
                      and all of our respective officers, agents, partners, and
                      employees, from and against any loss, damage, liability,
                      claim, or demand, including reasonable attorneys’ fees and
                      expenses, made by any third party due to or arising out
                      of: (1) use of the Site (2) breach of these Terms and
                      Conditions (3) any breach of your representations and
                      warranties outlined in these Terms and Conditions (4) your
                      violation of the rights of a third party, including but
                      not limited to intellectual property rights (5) any overt
                      harmful act toward any other user of the Site with whom
                      you connected via the Site
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-4db5529 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="4db5529"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0520799"
              data-id="0520799"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section className="none"></section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-ced7679 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="ced7679"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ee8d724 elementor-invisible"
                      data-id="ee8d724"
                      data-element_type="column"
                      data-settings='{"animation":"fadeInUp"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-6d8fdea elementor-widget elementor-widget-heading"
                          data-id="6d8fdea"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h4 class="elementor-heading-title elementor-size-default">
                              Limitation of Liability
                            </h4>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-f55811b elementor-widget elementor-widget-text-editor"
                          data-id="f55811b"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              In no event will we or our directors, employees,
                              or agents be liable to you or any third party for
                              any direct, indirect, consequential, exemplary,
                              incidental, special, or punitive damages,
                              including lost profit, lost revenue, loss of data,
                              or other damages arising from your use of the
                              site, even if we have been advised of the
                              possibility of such damages.
                            </p>
                            <p>
                              Not withstanding anything to the contrary
                              contained herein, our liability to you for any
                              cause whatsoever and regardless of the form of the
                              action will at all times be limited to the lesser
                              of the amount paid, if any, by you to us during
                              the ONE (1) month period before any cause of
                              action arising or US$100. State laws may not allow
                              limitations on implied warranties or the exclusion
                              or limitation of certain damages.
                            </p>
                            <p>
                              If certain State laws apply to you, some or all
                              disclaimers found within these terms and
                              conditions or limitations may not apply to you,
                              and you may have additional rights.
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-1b944bb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="1b944bb"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-d83f052 elementor-invisible"
                      data-id="d83f052"
                      data-element_type="column"
                      data-settings='{"animation":"fadeInUp"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-67278b4 elementor-widget elementor-widget-heading"
                          data-id="67278b4"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h4 class="elementor-heading-title elementor-size-default">
                              Exclusions
                            </h4>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-d8b884c elementor-widget elementor-widget-text-editor"
                          data-id="d8b884c"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              While the limitations above may not apply to you,
                              some countries do not allow exclusions or
                              limitations of liability for consequential
                              damages, so please check with your jurisdiction
                              before purchasing this insurance.
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-91b693f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="91b693f"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-b1d87b2 elementor-invisible"
                      data-id="b1d87b2"
                      data-element_type="column"
                      data-settings='{"animation":"fadeInUp"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-7c141eb elementor-widget elementor-widget-heading"
                          data-id="7c141eb"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h4 class="elementor-heading-title elementor-size-default">
                              Governing Law
                            </h4>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-92b2a8b elementor-widget elementor-widget-text-editor"
                          data-id="92b2a8b"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              These Terms and Conditions and your use of the
                              Site are governed by and construed following the
                              laws of the State of Florida applicable to
                              agreements made and to be entirely performed
                              within the State of Florida, without regard to its
                              conflict of law principles.
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-aaa2b41 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="aaa2b41"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-06bbe93 elementor-invisible"
                      data-id="06bbe93"
                      data-element_type="column"
                      data-settings='{"animation":"fadeInUp"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-dfdd22e elementor-widget elementor-widget-heading"
                          data-id="dfdd22e"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h4 class="elementor-heading-title elementor-size-default">
                              Changes
                            </h4>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-2f5ee9e elementor-widget elementor-widget-text-editor"
                          data-id="2f5ee9e"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              Supplemental terms and conditions or documents
                              posted on the Site from time to time are hereby
                              expressly incorporated herein by reference. In our
                              sole discretion, we reserve the right to make
                              changes or modifications to these Terms and
                              Conditions at any time and for any reason.
                            </p>
                            <p>
                              We will alert you about any changes by updating
                              the “Last Updated” date of these Terms and
                              Conditions, and you waive any right to receive
                              specific notice of each such change.
                            </p>
                            <p>
                              You must periodically review these Terms and
                              Conditions to stay informed of updates. You will
                              be subject to and will be deemed to have been made
                              aware of and accepted the changes in any revised
                              Terms and Conditions by your continued use of the
                              Site after the date such revised Terms and
                              Conditions are posted.
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
