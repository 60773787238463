import React from "react";
import logo from "../assets/images/logo-300x70.webp";
export default function Navbar({PhoneNumber}) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
   
      <div className="Navbar ">
        <div className="Navbar-c">
          <div className="logo">
            <a href="/"><img src={logo} alt="" /></a>
          </div>
          <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW: {PhoneNumber.PhoneNo}</a>
          </div>
        </div>
      </div>
  
  );
}
